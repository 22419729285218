@import url(https://fonts.googleapis.com/css2?family=Gemunu+Libre:wght@200&family=Merriweather:wght@300&display=swap);
body {
  background-image: linear-gradient(to right bottom, #00f260, #0575e6);
  background-attachment: fixed;
  font-family: sans-serif;
}

a {
  text-decoration: none;
  color: #354558;
}

a:hover {
  text-decoration: none;
  color: #000;
}

.app__main-content {
  background-color: #fff;
  border-radius: 20px;
  padding: 25px 15px;
  box-shadow: 0 0 10px black;
}

.app__row {
  height: 90vh;
}

.app__container {
  padding-top: 40px;
}

@media (max-width: 991px) {
  .app__main-content {
    margin: 30px 0px;
  }
}


/* MEDIA QUERY */
@media (max-width: 600px) {
  .app__main-content {
    margin-right: 10px;
    margin-left: 10px;
  }

  .navbar__active {
    font-size: 1.9rem;
  }

  .navbar__item {
    font-size: 1rem;
  }
}

/* NAVBAR CSS */

.navbar__items {
  display: flex;
}

.navbar__active {
  font-size: 2.5rem;
  font-weight: 700;
  color: #354358;
  border-bottom: 4px solid #00f260;
}

.navbar__item {
  margin: 20px;
  text-decoration: none;
  color: #354358;
  font-size: 1.3rem;
  cursor: pointer;
}

.navbar__item:hover {
  background: -webkit-linear-gradient(#00f260, #0575e6);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.navbar__message {
  font-size: 2.5rem;
}

/* SIDEBAR CSS */

.sidebar {
    background-color: #fff;
    height: 100%;
    border-radius: 20px;
    padding: 25px 15px;
    box-shadow: 0 0 10px black;
    text-align: center;
  }
  
  .sidebar__avatar {
    height: 120px;
    width: 120px;
    object-fit: contain;
    border-radius: 30px;
  }
  
  .sidebar__icon {
    width: 25px;
    height: 25px;
  }
  
  .sidebar__name {
    font-family: Kaushan Script;
    font-size: 1.7rem;
    margin: 20px 0;
  }
  
  .sidebar__name > span {
    color: #00f260;
  }
  
  .sidebar__item {
    margin-bottom: 10px;
    padding-top: 10px;
    font-size: 1.05rem;
    border-radius: 30px;
    height: 40px;
  }
  
  .sidebar__title {
    background-color: #f1f1f1;
    border-radius: 30px;
    padding-bottom: 35px;
    border-radius: 30px;
  }
  
  .sidebar__contact {
    background-color: #dbdddf;
    padding-top: 2px 0;
    margin: 20px -15px;
  }
  
  .sidebar__email {
    background-image: linear-gradient(to right bottom, #00f260, #0575e6);
    color: #fff;
    border-radius: 30px;
    margin: 15px 25px 0px;
    padding-bottom: 40px;
    cursor: not-allowed;
    transition: all 1.2s linear;
  }
  
  .sidebar__email:hover {
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
  }
  
  .sidebar__resume {
    padding-bottom: 35px;
  }
  
  .sidebar__github {
    padding-bottom: 40px;
  }
  
  .sidebar__github:hover,
  .sidebar__resume:hover {
    background-color: #f1f1f2;
    color: #000;
  }
/* ABOUT */

.about {
    padding: 20px;
    margin: -35px;
  }
  
  .about__intro {
    font-size: 1rem;
    padding: 20px 30px;
    margin: 10px 0;
  }
  
  .about__container {
    background-color: #dbdddf;
    padding: 30px 20px;
  }
  
  .about__heading {
    font-size: 25px;
    font-weight: bold;
    color: #354358;
    margin-left: 10px;
  }
  
  /* ABOUT->SKILL CARD */
  .skill-card {
    background-color: #fff;
    margin: 15px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    border-radius: 10px;
  }
  
  .skill-card__icon {
    width: 50px;
    height: 50px;
  }
  
  .skill-card__body {
    margin-left: 20px;
  }
  
  .skill-card__content {
    margin: 0;
    font-size: 0.99rem;
    color: #6e7e91;
  }
  
* RESUME */

.resume {
  padding-top: 20px;
}

.resume-card__heading {
  margin-left: 1px;
  font-size: 1.6rem;
  font-weight: 600;
  color: #354358;
}
.resume-card__title{
  font-size: 1.5rem;
}

.resume-language__heading {
  font-size: 1.6rem;
  font-weight: 500;
}

.resume-card__name {
  font-size: 16px;
}

.resume-card__body {
  margin-top: 10px;
}

.resume-card__title {
  font-weight: 500;
}

.resume-card__details {
  font-size: 0.9rem;
  margin-top: -15px;
}

/* RESUME->ABOUT */

.bar {
  width: 90%;
  background-color: #f1f1f3;
  border-radius: 30px;
  margin-bottom: 20px;
}

.bar__icon {
  width: 15px;
  height: 15px;
}

.bar__wrapper {
  background-image: linear-gradient(90deg, #00f260, #0575e6);
  border-radius: 20px;
  padding: 4px 14px;
  color: #fff;
  font-size: 0.9rem;
}

/* PROJECTS */

.project {
    height: 70vh;
    padding-top: 30px;
    overflow-y: auto;
  }
  
  .project-card__wrapper {
    margin: 10px 0;
    width: 100%;
    height: 100%;
    min-height: 160px;
  }
  
  .project-card__image {
    width: 100%;
    height: 80%;
    border-radius: 15px;
    border: 1px solid #00f260;
    transition: -webkit-transform 0.3s linear;
    transition: transform 0.3s linear;
    transition: transform 0.3s linear, -webkit-transform 0.3s linear;
  }
  .project-card__image:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .project-card__icon {
    width: 25px;
    height: 25px;
    margin-right: 5px;
  }
  
  .project-card__title {
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
  }
  
  .projects__navbar {
    display: flex;
    margin-bottom: 5px;
  }
  
  .projects__navbar > div {
    margin: 0 5px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .projects__navbar-active{
    color:#00f260;
  }
  
