/* PROJECTS */

.project {
    height: 70vh;
    padding-top: 30px;
    overflow-y: auto;
  }
  
  .project-card__wrapper {
    margin: 10px 0;
    width: 100%;
    height: 100%;
    min-height: 160px;
  }
  
  .project-card__image {
    width: 100%;
    height: 80%;
    border-radius: 15px;
    border: 1px solid #00f260;
    transition: transform 0.3s linear;
  }
  .project-card__image:hover {
    transform: scale(1.1);
  }
  
  .project-card__icon {
    width: 25px;
    height: 25px;
    margin-right: 5px;
  }
  
  .project-card__title {
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
  }
  
  .projects__navbar {
    display: flex;
    margin-bottom: 5px;
  }
  
  .projects__navbar > div {
    margin: 0 5px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .projects__navbar-active{
    color:#00f260;
  }
  