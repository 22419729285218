/* NAVBAR CSS */

.navbar__items {
  display: flex;
}

.navbar__active {
  font-size: 2.5rem;
  font-weight: 700;
  color: #354358;
  border-bottom: 4px solid #00f260;
}

.navbar__item {
  margin: 20px;
  text-decoration: none;
  color: #354358;
  font-size: 1.3rem;
  cursor: pointer;
}

.navbar__item:hover {
  background: -webkit-linear-gradient(#00f260, #0575e6);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.navbar__message {
  font-size: 2.5rem;
}
