/* SIDEBAR CSS */

.sidebar {
    background-color: #fff;
    height: 100%;
    border-radius: 20px;
    padding: 25px 15px;
    box-shadow: 0 0 10px black;
    text-align: center;
  }
  
  .sidebar__avatar {
    height: 120px;
    width: 120px;
    object-fit: contain;
    border-radius: 30px;
  }
  
  .sidebar__icon {
    width: 25px;
    height: 25px;
  }
  
  .sidebar__name {
    font-family: Kaushan Script;
    font-size: 1.7rem;
    margin: 20px 0;
  }
  
  .sidebar__name > span {
    color: #00f260;
  }
  
  .sidebar__item {
    margin-bottom: 10px;
    padding-top: 10px;
    font-size: 1.05rem;
    border-radius: 30px;
    height: 40px;
  }
  
  .sidebar__title {
    background-color: #f1f1f1;
    border-radius: 30px;
    padding-bottom: 35px;
    border-radius: 30px;
  }
  
  .sidebar__contact {
    background-color: #dbdddf;
    padding-top: 2px 0;
    margin: 20px -15px;
  }
  
  .sidebar__email {
    background-image: linear-gradient(to right bottom, #00f260, #0575e6);
    color: #fff;
    border-radius: 30px;
    margin: 15px 25px 0px;
    padding-bottom: 40px;
    cursor: not-allowed;
    transition: all 1.2s linear;
  }
  
  .sidebar__email:hover {
    transform: scale(1.15);
  }
  
  .sidebar__resume {
    padding-bottom: 35px;
  }
  
  .sidebar__github {
    padding-bottom: 40px;
  }
  
  .sidebar__github:hover,
  .sidebar__resume:hover {
    background-color: #f1f1f2;
    color: #000;
  }